import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    GetTransactionsListThunk
} from "../../../store/reducers/actions";

const Transactions = () => {
    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)
     const transactions = useAppSelector(store => store.transactionsReducer.transactionsData)


    useEffect(() => {
        dispatch(GetTransactionsListThunk(userToken))
    }, [])

    const getStatusText = (status) => {
        switch (status) {
            case 'New':
                return 'В ожидании';
            case 'Denied':
                return 'Отклонено';
            case 'Approved':
                return 'Одобрено';
            default:
                return status;
        }
    };

    return (
        <>

            <div className="currency-accounts-header">
                <h2>Последние Транзакции</h2>

            </div>

            <div className="account-currency-container" >
                <div className="account-table">
                    <div className="account-table-row header">
                        <div className="table-cell">Получатель</div>
                        <div className="table-cell">Сумма</div>
                        <div className="table-cell">Статус</div>
                    </div>
                    {transactions.map(trans => (
                        <div className="account-table-row" key={trans.key}>
                            <div className="table-cell cellText">{trans.recipient}</div>
                            <div className="table-cell cellText">{trans.amount} {trans.currency}</div>
                            <div className="table-cell cellText">{getStatusText(trans.transactionStatus)}</div>
                        </div>
                    ))}
                </div>
            </div>


        </>
    );
};

export default Transactions;
