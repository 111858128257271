import React, {useState} from 'react';
import './SignIn/SignIn.css';
import SignIn from "./SignIn/SignIn";
import SignInFinal from "./SignInFinal/SignInFinal";
import {Link} from "react-router-dom";
import BackIcon from "../../Assets/icons/arrow.svg";
import Logo from "../../Assets/logo_with_text.svg";

function SignInSteps() {

    const [current, setCurrent] = useState(0);
    const [dataForSend, setDataForSend] = useState({
        email: '',
        password: '',
        emailCode: '',
    })

    const steps = [{
        content: <SignIn setCurrent={setCurrent}
                         current={current} setLoginData={setDataForSend}
                         dataForSend={dataForSend}
        />
    }, {
        content: <SignInFinal setCurrent={setCurrent} dataForSend={dataForSend}
                              setDataForSend={setDataForSend}
                              current={current}
        />
    }];

    const handleBackClick = () => {
        if (current === 1) {
            setCurrent(0);
        }
    };

    return (
        <>

            <div className="wrapper">

                <div className="login_wrapper_content">
                    <Link to={current === 0 ? '/' : ''} onClick={handleBackClick}>
                        <img src={BackIcon} alt="Back" className="back_arrow_icon"/>
                    </Link>
                    <div className="sign_in_wrapper">
                        <div className="sign_in_content">
                            <img src={Logo} alt="Logo"/>
                            <div>{steps[current].content}</div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}

export default SignInSteps