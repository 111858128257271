import React, {useState} from 'react';
import './bankTransfer.css';
import BankTransferForm from "./banktransferForm";
import IntraTransferForm from "./intraTransferForm";

const TransferForm = () => {
    const [activeTab, setActiveTab] = useState('bankTransfer'); // Initially set to 'bankTransfer'

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div style={{ fontFamily: 'Arial, sans-serif' }}>
            <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '20px' }}>
                <button
                    onClick={() => handleTabChange('bankTransfer')}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: activeTab === 'bankTransfer' ? '#03045E' : '#ffffff',
                        color: activeTab === 'bankTransfer' ? '#ffffff' : '#03045E',
                        border: '1px solid #03045E',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginRight: '20px',
                        outline: 'none',
                        width: '308px'
                    }}
                >
                    Банковский Перевод
                </button>
                <button
                    onClick={() => handleTabChange('intraTransfer')}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: activeTab === 'intraTransfer' ? '#03045E' : '#ffffff',
                        color: activeTab === 'intraTransfer' ? '#ffffff' : '#03045E',
                        border: '1px solid #03045E',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        outline: 'none',
                        width: '308px'
                    }}
                >
                    Перевод на получателя СберКассы
                </button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'start' }}>
                {activeTab === 'bankTransfer' && <BankTransferForm />}
                {activeTab === 'intraTransfer' && <IntraTransferForm />}
            </div>
        </div>
    );
};

export default TransferForm;
