import React, {useState} from 'react'
import './aboutBank.css'
import MyBtn from '../ui/MyBtn/MyBtn'
import {useNavigate} from 'react-router-dom';
import Megaphone from '../Assets/bankIcons/building.svg'
import lightbulb from '../Assets/bankIcons/lightbulb.png'
import vector from '../Assets/bankIcons/Vector.png'
import vector1 from '../Assets/bankIcons/Vector1.png'
import card1 from '..//Assets/bankIcons/card1.png'
import card2 from '../Assets/bankIcons/card2.png'
import card3 from '../Assets/bankIcons/card3.png'
import rocket from '../Assets/bankIcons/rocket.svg'


function AboutBank() {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return (
        <>
            <div className='crypto-wrapper'>
                <div className='mainContainer'>
                    <div className='main'>
                        <div className='aboutBank'>
                            <h2 className="bankTextHeader">
                                О БАНКЕ
                            </h2>
                            <p className="bank-text">
                                Международная СберКасса - это современный Банк, обслуживающий <br/>
                                индивидуальных и корпоративных клиентов различных форм собственности и <br/>
                                направлений деятельности. В рамках современного дистанционного <br/>
                                банковского обслуживания, клиенты Банка эффективно используют систему <br/>
                                «Онлайн-Банкинга».
                            </p>
                            <MyBtn title="Открыть счет" large
                                   onClick={() => navigate("/register")}
                            />
                        </div>
                        <div className='megaphoneContainer'>
                            <img src={Megaphone}
                                 alt=''
                                 style={{height: "300px"}}
                            />
                        </div>
                        <div className='centralTextContainer'>
                            <div className='centralTextHeaderContainer'>
                                <p className='centralTextHeader'>
                                    Банк, предлагаающий <br/>
                                    инновационные решения
                                </p>
                                <div className='centralPara'>
                                    <img src={lightbulb} alt="lightbulb" style={{
                                        width: "120px",
                                        float: "right",
                                        marginTop: "-200px",
                                        marginRight: "-225px"
                                    }}/>
                                    <img src={vector1} style={{
                                        width: "-145px",
                                        float: "right",
                                        marginTop: "143px",
                                        marginRight: "-182px"
                                    }} alt=''/>
                                    <img src={vector} style={{width: "60px", float: "right", marginTop: "40px"}}
                                         alt=''/>
                                    <p>
                                        Развитие бизнеса Банка строится на предоставлении клиентам широкого <br/>
                                        спектра высокотехнологических банковских продуктов и услуг на уровне <br/>
                                        международных стандартов.
                                    </p>
                                    <p>
                                        Международная СберКасса постоянно изучает и прогнозирует потребности <br/>
                                        клиентов, осуществляет поиск новых решений, применяет передовые <br/>
                                        технические средства хранения, защиты, обработки и передачи информации, <br/>
                                        минимизирует бюрократические процедуры, что позволяет максимально <br/>
                                        сократить время прохождения проверок, платежей и всех видов расчетов.
                                    </p>
                                    <p>
                                        В основе взаимоотношений Банка с клиентами лежит принцип партнерства.<br/>
                                        Банк ориентирован на долгосрочное и взаимовыгодное сотрудничество.
                                    </p>
                                </div>
                                <div className="bankCards">
                                    <div
                                        className="cards"
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                    >
                                        <img src={card2} alt="Card 2"/>
                                        <div className="cardText" style={{display: isHovered ? 'block' : 'none'}}>
                                            Контроль Центробанка Коморских О-вов: Банковский надзор за деятельностью
                                            финансовой организации "СберКасса", осуществляет Mwali International
                                            Services Authority, г. Фомбони, автономный регион Союза Коморских Островов
                                            Мвали, Восточная Африка.
                                        </div>
                                        <div className="cardText" style={{display: isHovered ? 'none' : 'block'}}>
                                            Контроль Центробанка Коморских
                                            О-вов: Банковский надзор за деятельн...
                                        </div>
                                    </div>
                                    <div className="cards">
                                        <img src={card3} alt="Card 3"/>
                                        <div className="cardText">
                                            Уставной капитал Банка 1,437,714,201.00 Долларов США
                                        </div>
                                    </div>
                                    <div className="cards" onMouseEnter={() => setIsHovered2(true)}
                                         onMouseLeave={() => setIsHovered2(false)}>
                                        <img src={card1} alt="Card 1"/>
                                        <div className="cardText" style={{display: isHovered2 ? 'block' : 'none'}}>
                                            Контактные данные учреждения по надзору финансовых институтов на территории
                                            Коморских О-вов: www.mwaliregistrar.com misa@mwaliregistrar.com Звонки
                                            принимаются круглосуточно.
                                        </div>
                                        <div className="cardText" style={{display: isHovered2 ? 'none' : 'block'}}>
                                            Контактные данные учреждения по надзору финансовых институтов на территории Коморских О-вов:
                                        </div>
                                    </div>
                                </div>
                                <div className='rocketContainer'>

                                    <img src={rocket} alt="Rocket" className='rocketImage'/>
                                    <div className='underRocketText'>
                                        Готовы взлететь с нами в <br/>
                                        бесконечные финансовые просторы?
                                    </div>

                                    <div className="btn-block" style={{marginBottom: '300px'}}>
                                        <MyBtn
                                            title="Начните Сейчас" large
                                            onClick={() => navigate("/register")}
                                        />
                                    </div>
                                </div>


                            </div>

                        </div>


                    </div>
                </div>
            </div>


        </>
    )
}

export default AboutBank
