import React, {useEffect, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import './bankTransfer.css';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {SendTransferThunk} from "../../../store/reducers/actions";
import CustomModal from "../customModal/customModal";
import MyBtn from "../MyBtn/MyBtn";

const IntraTransferForm = () => {
    const dispatch = useAppDispatch()
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)

    const userAccounts = useAppSelector(store => store.userReducer.accountsList)
    const [minDate, setMinDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const minDateValue = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        setMinDate(minDateValue);
    }, []);


    const handleOkClick = () => {
        setIsPopupOpen(false);
        window.location.href = '/kabinet';  };

    const currencies = [
        'USD',
        'EUR',
        'GBP',
        'CHF',
        'RUB',
        'TRY',
        'AED',
        'CNY',
        'AUD',
        'CZK',
        'PLN',
        'CAD',
        'USDT'
    ];
    return (

        <div>

            <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Успешно!</div>
                <div className="modal-section">
                    Ваша заявка на перевод была отправлена.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {handleOkClick()}}/>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!</div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
                </div>
            </CustomModal>

            <h2 style={{color: '#03045E'}}>Перевод на получателя СберКассы</h2>
            <div className="bank-transfer-main-container">

                <Formik
                    initialValues={{
                        accNumberFrom: '',
                        fullName: '',
                        accNumberTo: '',
                        email: '',
                        transferDate: '',
                        amount: '',
                        transferCurrency: 'USD',
                        reference: '',
                        templateName: '',
                        type: 'internal'
                    }}
                    onSubmit={(values, actions) => {
                        dispatch(setDisabled(true))

                        dispatch(SendTransferThunk(userToken, values))
                            .then((res) => {
                                if (res.data === 'Created') {
                                    console.log('created')
                                    setIsPopupOpen(true)
                                } else {
                                    console.log('error')
                                    setIsErrorPopupOpen(true)
                                }
                            })
                            .catch((e) => {
                                console.log('error')
                                setIsErrorPopupOpen(true)
                            })
                            .finally(() => {
                                localStorage.removeItem("transfer-form")
                                dispatch(setDisabled(false))
                            })

                    }}
                >
                    <Form className="bank-transfer-form">
                        <div className='transfer-container'>
                            <div className="field-wrapper">
                                <label htmlFor="accNumberFrom"><span className="required">* </span> Счет: </label>
                                <Field as="select" name="accNumberFrom" id="accNumberFrom" required>
                                    <option style={{display: 'none'}}></option>
                                    {userAccounts.map(account => (
                                        <option key={account.key} value={account.number}>{account.number}</option>
                                    ))}
                                </Field>
                            </div>
                            <div className="field-wrapper">
                            </div>
                        </div>
                        <h2 style={{color: '#03045E'}}>Детали Получателя</h2>
                        <div className='transfer-container'>
                            <div className="field-wrapper">
                                <label htmlFor="paymentMethod"><span className="required">* </span> Метод перевода </label>
                                <Field as="select" name="paymentMethod" id="paymentMethod" required>
                                    <option style={{display: 'none'}}></option>
                                    <option value="account">Счет СберКассы</option>
                                </Field>
                            </div>
                            <div className="field-wrapper">
                                <label htmlFor="accNumberTo"><span className="required">* </span> Номер счета </label>
                                <Field type="text" name="accNumberTo" id="accNumberTo" placeholder="" required />
                            </div>
                        </div>

                        <div className='transfer-container'>
                            <div className="field-wrapper">
                                <label htmlFor="email">Электронная Почта: </label>
                                <Field type="email" name="email" id="email" placeholder=""  />
                            </div>
                        </div>
                        <div className='transfer-details-container'>
                            <h2 style={{color: '#03045E'}}>Детали перевода</h2>
                            <div className='currency-selection'>
                           {/*     <div className='field-wrapper'>
                                    <label htmlFor="paymentDate"><span className="required">* </span> Дата Перевода: </label>
                                    <Field type="date" name="paymentDate" id="paymentDate" placeholder="" min={minDate}  required />
                                </div>*/}
                                <div className="field-wrapper">
                                    <label htmlFor="amount"><span className="required">* </span> Сумма: </label>
                                    <Field type="text" name="amount" id="amount" placeholder="" required />
                                </div>
                                <div className="field-wrapper">
                                    <label htmlFor="transferCurrency"><span className="required">* </span> Валюта: </label>
                                    <Field as="select" name="transferCurrency" id="transferCurrency" required>
                                        {currencies.map(currency => (
                                            <option key={currency.key} value={currency}>{currency}</option>
                                        ))}
                                    </Field>
                                </div>
                            </div>
                            <div className="field-wrapper">
                                <label htmlFor="reference"> Причина: </label>
                                <Field as="textarea"  style={{ resize: 'none' }} name="reference" id="reference" placeholder="Введите причину перевода" rows="4" />
                            </div>
                        </div>
                       {/* <div className='translation-details-container'>
                            <h2>Сохранить детали перевода как шаблон</h2>
                            <div className="field-wrapper">
                                <label htmlFor="templateName">Наименование Шаблона: </label>
                                <Field type="text" name="templateName" id="templateName" placeholder="" />
                            </div>
                        </div>*/}
                        <div className='form-bottom-main-container'>
                            <div className='form-bottom-text-container'>
                                <p className='form-bottom-text'>
                                    <span className="required">* </span>  Обязательные поля
                                </p>
                            </div>
                            <button type="submit">Подтвердить</button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>


    );
};

export default IntraTransferForm;
