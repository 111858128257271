import React from 'react';

const TableColumns = [
    {
        title: 'Оффициальный курс',
        dataIndex: 'officialRate', 
        key: 'officialRate',
        render: (text) => (
            <span className="data-cell">
                {text}
            </span>
        ),
    },
    {
        title: 'Курс Сберкассы',
        dataIndex: 'sberBankRate', 
        key: 'sberBankRate',
        render: (text) => (
            <span className="data-cell">
                {text}
            </span>
        ),
    },
    {
        title: 'Тинькофф',
        dataIndex: 'tinkoffRate', 
        key: 'tinkoffRate',
        render: (text) => (
            <span className="data-cell">
                {text}
            </span>
        ),
    },
    {
        title: 'БСБ',
        dataIndex: 'bsbRate', 
        key: 'bsbRate',
        render: (text) => (
            <span className="data-cell">
                {text}
            </span>
        ),
    },
    {
        title: 'Сбербанк',
        dataIndex: 'sberbankRate', 
        key: 'sberbankRate',
        render: (text) => (
            <span className="data-cell">
                {text}
            </span>
        ),
    },
];

export default TableColumns;
