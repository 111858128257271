import React from 'react';
import './Teriff.css';
import AutoSlider from '../slider/Slider';
import {useNavigate} from 'react-router-dom';
import MyBtn from '../ui/MyBtn/MyBtn';

function Services() {
    const navigate = useNavigate();

    const tableData = [
        {leftContent: 'Отĸрытие Корпоративного Счета с персональным SWIFT/ SEPA IBAN: ', rightContent: '2,500 EUR'},
        {leftContent: 'Отĸрытие Корпоративного Счета с Общим SWIFT/SEPA IBAN:', rightContent: '1,500 EUR'},
        {leftContent: 'Отĸрытие Индивидуального Счета с персональным SWIFT/SEPA IBAN:', rightContent: '1,500 EUR'},
        {leftContent: 'Отĸрытие Индивидуального Счета с Общим SWIFT/SEPA IBAN:', rightContent: '1,000 EUR'},
        {
            leftContent: (
                <>
                    Отĸрытие Депозитного Счета: <br/>
                    <div className="muted">Минимальная сумма депозита:</div>
                </>), rightContent: (
                <>
                    500 EUR <br/>
                    <div className="muted">10,000 EUR</div>
                </>)
        },
        {
            leftContent: 'Обслуживание активного счета:', rightContent: (
                <>
                    30 EUR <br/>
                    <div className="muted">Ежемесячно</div>
                </>)
        },
        {leftContent: 'Обслуживание интернет-банкинга:', rightContent: 'Бесплатно'},
        {
            leftContent: 'Обслуживание клиента повышенного риска',
            rightContent: (
                <>
                    Cтавку назначает Банк <br/>
                    в зависимости от уровня риска клиента
                </>
            )
        },
        {leftContent: 'Индивидуальное ведение клиента:', rightContent: 'Входит в стоимость открытия счета'},
        {leftContent: 'Анализ деятельности на счетах Клиента:', rightContent: (
                <>
                    200 EUR <br/>
                    <div className="muted">Почасовая</div>
                </>)},
        {
            leftContent: (
                <>
                    Закрытие мультивалютного расчётного счета клиенту, <br/>
                    не соответствующему Клиентской политике Банка:
                </>
            ),
            rightContent: '100 EUR'
        },
        {leftContent: 'Предварительная проверка документов/комплайнс:', rightContent: 'Бесплатно'},
        {leftContent: 'Внутрибанковские перечисления (все валюты):', rightContent: 'Бесплатно'},
        {leftContent: 'Расследование и ручная обработка входящего платежа:', rightContent: '50 EUR'},
        {leftContent: 'Отзыв или внесение изменений в платёжное распоряжение:', rightContent: '30 EUR'},
        {leftContent: 'Конвертационные операции, проводимые по курсу купли/продажи Банка:', rightContent: 'Бесплатно'},
        {leftContent: 'Конвертационные операции в криптовалюте:', rightContent: 'до 2,5%'},
        {leftContent: 'Международный перевод, входящий/исходящий:', rightContent: (
                <>
                    0.30% <br/>
                    <div className="muted">(Мин. 30 EUR, Макс. 500 EUR)</div>
                </>)},


    ];

    return (
        <>
            <div className="corporate-wrapper">
                <div className='corporate-container'>
                    <div className="corporate-text-content ">
                        <div className="corporate-title-block ">
                            <div className="corporate-text-content">
                                <div className="terif-header">Услуги на все случаи жизни</div>
                            </div>
                        </div>
                        <AutoSlider/>
                        <div className="btn-teriff-block">
                            <MyBtn
                                title="Другие Услуги" large
                                onClick={() => navigate("")}
                            />
                        </div>

                        <div className="home-title">Выгодные Тарифы</div>

                        <div className="static-table">
                            <div className="table-row header">
                                <div className="table-cell">Наименование</div>
                                <div className="table-cell">Сумма</div>
                            </div>
                            {tableData.map((rowData, index) => (
                                <div className="table-row" key={index}>
                                    <div className="table-cell cellText">{rowData.leftContent}</div>
                                    <div className="table-cell cellText">{rowData.rightContent}</div>
                                </div>
                            ))}
                        </div>
                        {/* Table End */}

                    </div>
                </div>
            </div>
        </>
    );
}

export default Services;
