import React, { useState, useEffect } from 'react';
import axios from 'axios';/*
import { Table } from 'antd';*/
import Columns from './columns';
import './templateSheet.css';

const TemplateSheet = () => {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    fetchData();
  }, []); 

  const fetchData = async () => {
    try {
      const response = await axios.get('our-api-url');
      setEntries(response.data);
    } catch (error) {
      console.error('Error fetching data Please try again:', error);
    }
  };

  const deleteEntry = async (key) => {
    try {
      await axios.delete(`our-api-url/${key}`);
      setEntries(entries.filter((entry) => entry.key !== key));
    } catch (error) {
      console.error('Error deleting entry Please try again:', error);
    }
  };
  const sampleData = [
    { key: '1', accountNumber: '123456', name: 'name', recipient: 'Company XYZ', translationType: 'Document Translation' },
    { key: '2', accountNumber: '789012', name: 'name2', recipient: 'Company ABC', translationType: 'Website Localization' },
  ];

  return (

          <div className="table-container">
            <h3>Раздел находится в разработке. Благодарим за понимание.</h3>
    {/*  <Table
        columns={Columns({ deleteEntry })}
        dataSource={sampleData}
        pagination={false}
      />
      <div className='btn-container'>
        <button className="button" onClick={fetchData} style={{ cursor: 'pointer' }}>Add new</button>
      </div>*/}
    </div>

    
  );
};

export default TemplateSheet;
