import React, {useState} from 'react';
import {useAppDispatch} from "../../../../store/store";
import {Formik} from "formik";
import {finalLogin} from "../../../../store/reducers/actions";
import MyInput from "../../../ui/MyInput/MyInput";
import MyBtn from "../../../ui/MyBtn/MyBtn";
import {zeroFill} from "../../../../helpers/zeroFill";
import CustomModal from "../../../ui/customModal/customModal";
import {useNavigate} from "react-router-dom";

function SignInFinal(props) {
    const dispatch = useAppDispatch()
    const [time, setTime] = useState(120)
    const [timeIsOver, setTimeIsOver] = useState(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)


    const tick = () => {
        if (time === 0) {
            setTimeIsOver(true)
            props.setCurrent(0)
            return
        }
        setTime(prevState => prevState - 1)
    }
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    React.useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });
    const navigate = useNavigate();

    return (
        <>
            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!</div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
                </div>
            </CustomModal>


            <Formik
                initialValues={{
                    email: props.dataForSend.email,
                    password: props.dataForSend.password,
                    emailCode: ''
                }}
                onSubmit={(values, actions) => {
                    dispatch(finalLogin(values))
                        .then(() => {
                            console.log('Login successful!');
                            navigate('/kabinet');
                        })
                        .catch(error => {
                            console.error('Login error:', error);
                            setIsErrorPopupOpen(true)
                        })
                        .finally(() => {
                            actions.setSubmitting(false);
                        });
                }}
            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <div className="sign_in_content">
                            <div className="title">
                                Код был отправлен на вашу электронную почту
                            </div>
                            <div className="my_input">
                                <MyInput
                                    id="emailCode"
                                    name="emailCode"
                                    placeholder="Код подтверждения"
                                    value={formikProps.values.emailCode}
                                    onChange={formikProps.handleChange}
                                />
                            </div>

                            <div className="btn_block">
                                <MyBtn title={"Отправить"} type={"submit"} style={{height: '50px', width: '200px'}}/>
                            </div>

                            <div className="dont_have_account">
                                {timeIsOver
                                    ? <div></div>
                                    : <div> {zeroFill(minutes, 2)} : {zeroFill(seconds, 2)}</div>
                                }
                            </div>

                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default SignInFinal