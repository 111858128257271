import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {useDispatch, useSelector} from 'react-redux';
import userReducer from './reducers/UserSlice'
import transactionsReducer from './reducers/TransactionsSlice'

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    userReducer,
    transactionsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export const persistor = persistStore(store);


export default store;
