import React from 'react';
import './Footer.css'
import {Link} from "react-router-dom";
import footerLogo from '../Assets/logo-text.svg'
import locationIcon from '../Assets/footerImages/location.svg'
import call from '../Assets/footerImages/call.svg'
import mail from '../Assets/footerImages/mail.svg'
import facebook from '../Assets/footerImages/facebook.svg'
import instagram from '../Assets/footerImages/instagram.svg'
import linkedin from '../Assets/footerImages/linkedin.svg'
import whatsapp from '../Assets/footerImages/whatsapp.svg'

const Footer = () => {


    return (
        <>
            <footer className="footer_wrapper">
                <div className="footer_logo_block">
                    <img src={footerLogo} alt={""} width={144}/>
                    <span className="copyright">Номер Лицензии: B2023080</span>
                    <span className="copyright">Дата выдачи: 02.05.2022</span>
                    <span className="copyright">Срок действия: Бессрочная</span>
                    <span className="copyright">Орган, выдавший лицензию:  <br/>
                        Mwali International Services Authority, <br/> Fomboni, Union of Comoros</span>
                    <span className="copyright">все права защищены © 2024 <br/>
                            Международная СберКасса</span>
                </div>

                <div className="links_block">
                    <div className="links_column">
                        <span className="links_title">Общее</span>
                        <div className="mobile_row">
                            <Link className="link">Гланая</Link>
                            <Link className="link">О банке</Link>
                            <Link className="link">Тарифы банка</Link>
                            <Link className="link">Реквизиты</Link>

                        </div>

                    </div>

                    <div className="links_column">
                        <span className="links_title">Услуги</span>
                        <Link className="link">Корпоративным клиентам</Link>
                        <Link className="link">Операции с криптовалютой</Link>
                        <Link className="link">Обналичивание банковских чеков</Link>
                        <Link className="link">Международный эквайринг</Link>
                        <Link className="link">Операции с драгоценными металлами</Link>
                        <Link className="link">Международная СберКасса FX</Link>

                    </div>

                    <div className="links_column">
                        <span className="links_title">Контакты</span>
                        <div className="link">
                            <div>
                                <img src={locationIcon} style={{marginRight: '10px'}} alt={""} width={24} height={24}/>
                            </div>
                            <div>
                                Glandore Office Building, Fitzwilliam Hall, 26 Fitzwilliam <br/>
                                Pl, Dublin 2, D02 T292, Ireland
                                <div style={{color: '#979797'}}>
                                    (Обслуживание физических лиц осуществляется по <br/>
                                    предварительной записи)
                                </div>
                            </div>
                        </div>
                        <div className="link">
                            <div>
                                <img src={locationIcon} style={{marginRight: '10px'}} alt={""} width={24} height={24}/>
                            </div>
                            <div>
                                Bonovo Road – Fomboni Island of Mohéli – Comoros Union
                            </div>
                        </div>
                        <div className="link">
                            <div>
                                <img src={call} style={{marginRight: '10px'}} alt={""} width={24} height={24}/>
                            </div>
                            <div>
                                +353 81 888 3938
                            </div>
                        </div>
                        <div>
                            <a className="link" href="mailto:info@mezhdunarodnaya-sberkassa.com">
                                <div>
                                    <img src={mail} style={{marginRight: '10px'}} alt={""} width={24} height={24}/>
                                </div>
                                <div>
                                    info@mezhdunarodnaya-sberkassa.com
                                </div>
                            </a>
                        </div>
                        <div className="icons_block">
                            <a href="https://www.facebook.com/sbarkassa" target={"_blank"}>
                                <img src={facebook} alt={""} width={28} height={28}/>
                            </a>
                            <img src={instagram} alt={""} width={28} height={28}/>
                            <a href="https://www.linkedin.com/company/sbarkassa" target={"_blank"}>
                                <img src={linkedin} alt={""} width={28} height={28}/>
                            </a>
                            <img src={whatsapp} alt={""} width={28} height={28}/>
                        </div>
                    </div>
                </div>


            </footer>
        </>

    );
};

export default Footer;
