import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import MyBtn from "../ui/MyBtn/MyBtn";
import Safe from "../Assets/clients/safe.svg";
import USD from "../Assets/clients/usd.svg";
import Calendar from "../Assets/clients/calendar.svg";
import PieChart from "../Assets/clients/pie-chart.svg";
import Discount from "../Assets/clients/discount.svg";
import Money from "../Assets/clients/money.svg";
import Pin from "../Assets/clients/pin.svg";
import MasterCard from "../Assets/clients/Mastercard.svg";
import UnionPay from "../Assets/clients/Union Pay.svg";
import ApplePay from "../Assets/clients/ApplePay.svg";
import Discover from "../Assets/clients/Discover.svg";
import Express from "../Assets/clients/Express.svg";
import JCB from "../Assets/clients/JCB.svg";
import Visa from "../Assets/clients/Visa.svg";
import Gold from "../Assets/clients/gold.svg";
import FX from "../Assets/clients/fx.svg";
import './OtherServices.css';
import card2 from "../Assets/clients/gold.png";
import card3 from "../Assets/clients/gold2.png";
import card1 from "../Assets/clients/building.png";

function OtherServices() {

    const navigate = useNavigate();

    useEffect(() => {
        const href = window.location.href
        if (href.includes("#")) {
            const id = `${href.substring(href.indexOf("#") + 1)}`
            const anchor = document.getElementById(id)
            if (anchor) {
                anchor.scrollIntoView({behavior: "smooth"})
            }
        }
    }, [])

    return (
        <>
            <div className="other-wrapper">
                <div className="home-container">
                    <div className="text-container" id="deposit">
                        <div className="text-content">
                            <div className="home-title">Депозитный счет</div>
                            <div className="home-description">
                                Депозиты за рубежом – это надежный, простой и законный способ снизить налоговое бремя
                                и сохранить капитал. Депозит за границей нужно рассматривать не только как возможность
                                сохранить и приумножить средства, но и как выгодное вложение.
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={Safe} alt="Notes" style={{height: '320px'}}/>
                        </div>

                    </div>

                    <div className="text-container">
                        <div className="cards-section">
                            <div className="cards-text">
                                <div className="home-title" style={{textTransform: 'none'}}>
                                    Преимущества и Выгода
                                </div>
                            </div>
                            <div className="cards-container">
                                <div className="card">
                                    <div className="card-title">Приватность
                                    </div>
                                    <div className="card-content">“Международная СберКасса не разглашает сведения о
                                        сумме и наличие счетов клиента государственным структурам ни при каких
                                        обстоятельствах.”
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-title" style={{textTransform: 'none'}}>Безопасность и
                                        Стабильность
                                    </div>
                                    <div className="card-content">"Экономическая ситуация Банков-корреспондентов с
                                        которыми мы сотрудничаем более стабильна, чем в
                                        странах СНГ, что снижает сторонние риски по вкладам и депозитным счетам."
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-title">Доходность
                                    </div>
                                    <div className="card-content">"Мы предлагаем до 11% годовых новым Клиентам, а также
                                        до 12% годовых
                                        Клиентам с историей в нашем Банке более двух лет."
                                    </div>
                                </div>


                            </div>
                            <div className="cards-btn">
                                <MyBtn
                                    title="Открыть Счет" large
                                    onClick={() => navigate("/register")}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="text-container">
                        <div className="cards-header">Международная СберКасса
                            готова предоставить следующие условия
                        </div>

                    </div>

                    <div className="others-card">
                        <div className="card-info"><span>
                            <div className="client-title">
                                Минимальная сумма:</div>
                            <div className="card-description">
                               10 000 EUR, USD (другие валюты по запросу)</div>
                        </span>
                            <span className="card-icon">
                                <img src={USD} alt="usd"/>
                            </span>
                        </div>
                    </div>

                    <div className="others-card">
                        <div className="card-info"><span>
                            <div className="client-title">
                               Срок размещения:</div>
                            <div className="card-description">
                              от 3 месяцев до 5 лет</div>
                        </span>
                            <span className="card-icon">
                                <img src={Calendar} alt="calendar"/>
                            </span>
                        </div>

                    </div>

                    <div className="others-card">
                        <div className="card-info"><span>
                            <div className="client-title">
                                Выплата процентов:</div>
                            <div className="card-description">
                               раз в 1, 3, 6, 12 месяцев</div>
                        </span>
                            <span className="card-icon">
                                <img src={PieChart} alt="pie-chart"/>
                            </span>
                        </div>
                    </div>

                    <div className="others-card double">
                        <div className="card-info"><span>
                            <div className="client-title">
                               Процент выплаты по депозитам для Клиентов без истории:</div>
                            <div className="card-description">
                             10% EUR; 11% USD <br/>
Сроки выплаты 1-3 месяца: 9,5% EUR; 10,5% USD<br/>Сроки выплаты 6-12 месяцев: 10% EUR; 11% USD</div>
                        </span>
                            <span className="card-icon">
                                <img src={Discount} alt="Discount"/>
                            </span>
                        </div>
                    </div>

                    <div className="others-card double">
                        <div className="card-info"><span>
                            <div className="client-title">
                                Процент выплаты по депозитам для Клиентов с историей более одного года:</div>
                            <div className="card-description">
                               11% EUR; 12% USD <br/>
                                Сроки выплаты 1-3 месяца: 10,5% EUR; 11,5% USD<br/>
                                Сроки выплаты 6-12 месяцев: 11% EUR; 12% USD</div>
                        </span>
                            <span className="card-icon">
                                <img src={Discount} alt="Discount"/>
                            </span>
                        </div>
                    </div>

                    <div id="cashing-checks" className="text-container" style={{marginTop: '300px'}}>
                        <div className="image-container">
                            <img src={Money} alt="Money" style={{height: '320px'}}/>
                        </div>
                        <div className="text-right">
                            <div className="home-title">Обналичивание
                                банковских чеков
                            </div>
                            <div className="home-description">
                                Международная СберКасса предлагает услугу обналичивания банĸовсĸих чеĸов. Условия
                                обналичивания: 100 EUR, USD + 2% от суммы самого чека.
                                Процесс обналичивания чеков зависит от Банка-эмитента.
                                Среднее время ожидания варьируется от 7-ми до 14-ти банковских дней.
                            </div>
                        </div>
                    </div>

                    <div className="text-container" id="international-acquiring" style={{marginTop: '300px'}}>
                        <div className="text-content">
                            <div className="home-title">Международный
                                Эквайринг
                            </div>
                            <div className="home-description">
                                Прием международных платежей для онлайн бизнеса. Вы сможете принимать платежи в 150+
                                странах мира и выводить заработанные средства на счет компании или ваши персональные
                                реквизиты, а также на криптокошелек.
                                Условия и тарифы уточняются у вашего персонального Менеджера.
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={Pin} alt="Pin" style={{height: '440px'}}/>
                        </div>
                    </div>

                    <div className="brands-row">
                        <div className="rectangle">
                            <img src={Visa} alt="brand"/>
                        </div>
                        <div className="rectangle">
                            <img src={MasterCard} alt="brand"/>
                        </div>
                        <div className="rectangle">
                            <img src={UnionPay} alt="brand"/>
                        </div>
                        <div className="rectangle">
                            <img src={ApplePay} alt="brand"/>
                        </div>
                        <div className="rectangle">
                            <img src={Discover} alt="brand"/>
                        </div>
                        <div className="rectangle">
                            <img src={Express} alt="brand"/>
                        </div>
                        <div className="rectangle">
                            <img src={JCB} alt="brand"/>
                        </div>
                    </div>

                    <div className="quote-container" style={{display: 'inline', maxWidth: '1200px'}}>
                        <div className="quote-text">"Мы успешно справляемся с нестандартными задачами и готовы
                            предложить гарантию лучших условий по сравнению с нашими конкурентами."
                        </div>
                    </div>

                    <div className="text-container" id="operations-metals" style={{marginTop: '300px'}}>
                        <div className="image-container">
                            <img src={Gold} alt="Money" style={{maxWidth: '464px'}}/>
                        </div>
                        <div className="text-right">
                            <div className="home-title">Операции с
                                Драгоценными Металлами
                            </div>
                            <div className="home-description">
                                Приобретение золотых слитков - классический способ вложения в золото для сохранения
                                капитала. Каждый год в мире покупается около тысячи тонн золотых слитков. Исследования
                                показывают, что оптимальным долгосрочным вложением в золото является 2-10% от общей
                                стоимости портфеля, в зависимости от уровня риска, что способно обеспечить защиту и рост
                                доходности инвестиционного портфеля.
                            </div>
                        </div>
                    </div>

                    <div className="cards-section">
                        <div className="cards-container">
                            <div className="cards">
                                <img src={card1} alt="Card 1"/>
                                <div className="cardText" style={{background: 'rgba(3, 4, 94, 0.25)'}}>
                                    Международная СберКасса предлагает возможность приобретения золотых слитков напрямую
                                    с
                                    немецкого аффинажного завода C.HAFNER GmbH + Co. KG.
                                </div>
                            </div>
                            <div className="cards">
                                <img src={card2} alt="Card 2"/>
                                <div className="cardText" style={{background: 'rgba(3, 4, 94, 0.25)'}}>
                                    Мы предоставляем возможность приобрести золото 999,9 пробы в слитках стандарта LBMA
                                    (Лондонская Биржа драгоценных металлов) с возможностью хранения в банке..
                                </div>
                            </div>
                            <div className="cards">
                                <img src={card3} alt="Card 3"/>
                                <div className="cardText" style={{background: 'rgba(3, 4, 94, 0.25)'}}>
                                    При покупке физического золота с доставкой, Вы можете выбрать предпочтительный вес
                                    слитка – 5, 10, 20, 50, 100, 250, 500 грамм, 1 унцию, или купить...
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-container" id="sberkassa-fx" style={{marginTop: '300px'}}>
                        <div className="text-content">
                            <div className="home-title">Международная <br/>
                                СберКасса FX
                            </div>
                            <div className="home-description">
                                Прием международных платежей для онлайн бизнеса. Вы сможете принимать платежи в 150+
                                странах мира и выводить заработанные средства на счет компании или ваши персональные
                                реквизиты, а также на криптокошелек.
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={FX} alt="FX" style={{height: '430px'}}/>
                        </div>
                    </div>

                    <div className="text-container">
                        <div className="cards-section">
                            <div className="cards-text">
                                <div className="home-title" style={{textAlign: 'center', textTransform: 'none'}}>
                                    Мы предлагаем своим Клиентам возможность торговли на рынке Forex в условиях
                                    реального спроса и предложения:
                                </div>
                                <p style={{textTransform: 'none'}}>
                                    <div className="benefit-row">
                                        <div className="benefit-no">01</div>
                                        <div className="benefit-text">
                                            Торговля валютами металлами и нефтью    </div>
                                    </div>
                                    <div className="benefit-row">
                                        <div className="benefit-no">02</div>
                                        <div className="benefit-text">
                                            Ликвидность от ведущих банков-маркетмейкеров    </div>
                                    </div>
                                    <div className="benefit-row">
                                        <div className="benefit-no">03</div>
                                        <div className="benefit-text">
                                            Моментальное исполнение ордеров с помощьютехнологий STP   </div>
                                    </div>
                                    <div className="benefit-row">
                                        <div className="benefit-no">04</div>
                                        <div className="benefit-text">
                                            Узкие спреды и котировки от основных поставщиков ликвидности    </div>
                                    </div>
                                    <div className="benefit-row">
                                        <div className="benefit-no">05</div>
                                        <div className="benefit-text">
                                            Поддержка скальпинга     </div>
                                    </div>
                                    <div className="benefit-row">
                                        <div className="benefit-no">06</div>
                                        <div className="benefit-text">
                                            Отсутствие ограничений по дистанции выставления стоп и лимит ордеров     </div>
                                    </div>
                                    <div className="benefit-row">
                                        <div className="benefit-no">07</div>
                                        <div className="benefit-text">
                                            Открытие счета, зачисление и вывод средств. <br/>
                                            После внутренних проверок на одобрение открытия счета, зачисление или вывод средств производятся банковским платежом на сновании соответствующего распоряжения.   </div>
                                    </div>
                                    <div className="benefit-row">
                                        <div className="benefit-no">08</div>
                                        <div className="benefit-text">
                                            Пополнение счёта, вывод средств осуществляется с 09:00 до 17:30 CET <br/>
                                            Условия и тарифы уточняются у Вашего персонального Менеджера.    </div>
                                    </div>

                                </p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


        </>
    )
}

export default OtherServices
