import React, {useEffect, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {setDisabled, userSlice} from "../../../store/reducers/UserSlice";
import {sendPersonalKycThunk, SendTransferThunk, setTokenState} from "../../../store/reducers/actions";
import CustomModal from "../customModal/customModal";
import MyBtn from "../MyBtn/MyBtn";

const KycForm = () => {
    const dispatch = useAppDispatch()
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const accountType = useAppSelector(store => store.userReducer.user.userData.accountType)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
    const idCardsTypes = ["Паспорт", "Национальное ID", "Водительские Права"];
    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)
    const [formSubmitted, setFormSubmitted] = useState(false); // Initialize formSubmitted state

    return (

        <div>

            <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Успешно!</div>
                <div className="modal-section">
                    Ваша заявка была отправлена на рассмотрение.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {setIsPopupOpen(false)}}/>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!</div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
                </div>
            </CustomModal>

            <h2 style={{color: '#03045E'}}>Верификация Клиента</h2>
            { (kycStatus === 'New' || formSubmitted) ?
                <div className="bank-transfer-main-container">
                    <h4>Ваша заявка на верификацию была отправлена на рассмотрение.</h4>
                </div>
                :
                <div className="bank-transfer-main-container">

                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        companyName: '',
                        countryName: '',
                        city: '',
                        state: '',
                        postalCode: '',
                        identityCardType: '',
                        identityCardId: '',
                        address: '',
                        phoneNumber: '',
                        taxNumber: '',
                        sourcesOfWealth: [],
                        isOtherSourcesOfWealth: false,
                        otherSourcesOfWealth: '',
                        pep: false,
                        currencies: [],
                    }}
                    onSubmit={(values, actions) => {
                        dispatch(setDisabled(true))

                        dispatch(sendPersonalKycThunk(userToken, values))
                            .then((res) => {
                                if (res.data === "Created") {
                                    setIsPopupOpen(true)
                                    setFormSubmitted(true);
                                } else {
                                    setIsErrorPopupOpen(true)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                setIsErrorPopupOpen(true)
                                if (error.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                }
                                /*
                                                                if (error.response.data === "Too many phones. Max count is 5") {
                                                                    setErrorMessage(error.response.data)
                                                                    setIsErrorModalOpen(true)
                                                                    return
                                                                }

                                                                if (error.response.data === "Phone already exist") {
                                                                    setErrorMessage("This phone number already exists. Please try another phone number.")
                                                                    setIsErrorModalOpen(true)
                                                                    return
                                                                }

                                                                if (error.code === "ERR_NETWORK" && !error.response) {
                                                                    setErrorMessage("Maximum files size is 90 MB")
                                                                    setIsErrorModalOpen(true)
                                                                } else {
                                                                    setErrorMessage("Something went wrong. Please try again later.")
                                                                    setIsErrorModalOpen(true)
                                                                }*/
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }}
                >
                    <Form className="bank-transfer-form">
                        {accountType === 'business' &&
                            <div className='transfer-container'>
                                <div className="field-wrapper">
                                    <label htmlFor="companyName"><span className="required">* </span> Имя Компании</label>
                                    <Field type="text" name="companyName" id="companyName" placeholder="" required />

                                </div>
                            </div> }
                        {accountType === 'personal' &&
                            <div className='transfer-container'>
                                <div className="field-wrapper">
                                    <label htmlFor="firstName"><span className="required">* </span> Имя</label>
                                    <Field type="text" name="firstName" id="firstName" placeholder="" required />

                                </div>
                                <div className="field-wrapper">
                                    <label htmlFor="lastName"><span className="required">* </span> Фамилия</label>
                                    <Field type="text" name="lastName" id="lastName" placeholder="" required />
                                </div>
                            </div>
                        }
                        <div className='transfer-container'>
                            <div className="field-wrapper">
                                <label htmlFor="countryName"><span className="required">* </span>Страна </label>
                                <Field type="text" name="countryName" id="countryName" required/>

                            </div>
                            <div className="field-wrapper">
                                <label htmlFor="phoneNumber"><span className="required">* </span> Номер телефона </label>
                                <Field type="text" name="phoneNumber" id="phoneNumber" placeholder="" required />
                            </div>
                        </div>

                        <div className='transfer-container'>
                            <div className="field-wrapper">
                                <label htmlFor="city">Город </label>
                                <Field type="text" name="city" id="city" placeholder=""  />
                            </div>
                            <div className="field-wrapper">
                                <label htmlFor="address">Адрес </label>
                                <Field type="text" name="address" id="address" placeholder=""  />
                            </div>
                        </div>

                        <div className='transfer-container'>
                            <div className="field-wrapper">
                                <label htmlFor="identityCardType">Тип удостоверения личности </label>
                                <Field as="select" name="identityCardType" id="identityCardType" >
                                    <option style={{display: 'none'}}></option>
                                    {idCardsTypes.map(type => (
                                        <option key={type.key} value={type}>{type}</option>
                                    ))}
                                </Field>
                            </div>
                            <div className="field-wrapper">
                                <label htmlFor="identityCardId">Номер </label>
                                <Field type="text" name="identityCardId" id="identityCardId" placeholder=""  />
                            </div>
                        </div>

                        <div className='form-bottom-main-container'>
                            <div className='form-bottom-text-container'>
                                <p className='form-bottom-text'>
                                    <span className="required">* </span>  Обязательные поля
                                </p>
                            </div>
                            <button type="submit">Подтвердить</button>
                        </div>
                    </Form>
                </Formik>
            </div>

            }
              </div>


    );
};

export default KycForm;
