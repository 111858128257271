import React from 'react';
import {useNavigate} from 'react-router-dom';
import './CryptoOperations.css'
import Crypto from '../Assets/cryptoIcons/crypto.svg'
import MyBtn from '../ui/MyBtn/MyBtn';
import Notes from "../Assets/clients/notes.svg";


function CryptoOperations() {
    const navigate = useNavigate();

    return (
        <>
            <div className='crypto-wrapper'>
                <div className='crypto-container'>

                    <div className="text-container" style={{marginTop: '90px'}}>
                        <div className="title-block">
                            <div className="text-content">
                                <div className="home-title"> Операции с <br/> Криптовалютой</div>
                                <div className="home-description">
                                    Мы поддерживаем ĸонцепцию диджитализации и децентрализации валюты в современном
                                    мире. Также Международная СберКасса предоставляет возможность криптообменных
                                    операций. У нас нет ограничений на объемы обмена криптовалют, поэтому вы можете
                                    свободно конвертировать любую сумму. Международная СберКасса взимает до 2,5 процента
                                    за конвертацию в зависимости от объема.
                                </div>
                                <div className="btn-block">
                                    <MyBtn
                                        title="Открыть Счет" large
                                        onClick={() => navigate("/register")}
                                    />
                                </div>
                            </div>
                            <div className="image-container">
                                <img src={Crypto} alt="crypto" style={{height: '353px'}}/>
                            </div>
                        </div>
                    </div>

                    <div className='center-container'>

                        <div className='center-headings-container'>
                            <div className='center-heading'>
                                Международная СберКасса предоставляет <br/>
                                платежный шлюз для криптоплатежей
                            </div>
                        </div>
                        <div className='center-heading-description'>
                            <div className='center-heading-details'>
                                Это сервис, который позволяет продавцам обрабатывать платежи <br/>
                                криптовалютах. Он действует как посредник и выполняет 6 основных функций:
                            </div>
                        </div>


                        <div className="benefit-row" style={{paddingTop: '64px'}}>
                            <div className="benefit-no">01</div>
                            <div className="benefit-text">
                                Регистрирует данные
                                плательщика
                            </div>
                        </div>

                        <div className="benefit-row">
                            <div className="benefit-no">02</div>
                            <div className="benefit-text">
                                Отправляет данные транзакции в блокчейн
                                и регистрирует подтверждения от узлов
                            </div>
                        </div>

                        <div className="benefit-row">
                            <div className="benefit-no">03</div>
                            <div className="benefit-text">
                                Предоставляет подтверждение обработки платежа для обеих сторон
                            </div>
                        </div>
                        <div className="benefit-row">
                            <div className="benefit-no">04</div>
                            <div className="benefit-text">
                                Конвертирует
                                полученные монеты в
                                фиатные деньги (по
                                запросу мерчанта)
                            </div>
                        </div>
                        <div className="benefit-row">
                            <div className="benefit-no">05</div>
                            <div className="benefit-text">
                                Принимает платежи от покупателя
                            </div>
                        </div>
                        <div className="benefit-row">
                            <div className="benefit-no">06</div>
                            <div className="benefit-text">
                                Переводит платежи мерчанту
                            </div>
                        </div>
                        {/* <div className='layerFour-container'>

                                                
                                                    <img src={hands} alt="hands"  className='layerFourth-img'/>
                                            </div> */}

                    </div>

                </div>
            </div>
        </>
    );

}


export default CryptoOperations;