import axios from "axios";

const baseSettings = {
    headers: {
        'Content-Type': 'application/json',
    },
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    ...baseSettings
});

export const Api = {
    login(data) {
        return instance.post('api/login/list', data)
            .then(response => response.data)
            .catch(error => {
                throw error;
            });
    },
    register(data) {
        return instance.post('api/registrations', data)
            .then(response => response.data)
            .catch(error => {
                throw error;
            });
    },
    finalLogin(data) {
        return instance.post('api/login', data)
    },
    resetPassword (data) {
        return instance.post(`/api/user/password/refresh`, data)
            .then(response => response.data)
            .catch(error => {
                throw error;
            });
    },
    getAccountsList (token) {
        return instance.get('api/account/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    createAccounts(token, accounts) {
        return instance.post('/api/account/create', accounts  ,{headers: {Authorization: `Bearer ${token}`}})
    },
    getTransactionsList (token: string) {
        return instance.get('api/transaction/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    sendTransfer (token: string,data: any) {
        return instance.post('/api/transaction/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendPersonalKyc (token: string, data: any) {
        return instance.post('api/kyc/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getKycList (token: string) {
        return instance.get('api/kyc/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    deleteKyc (token: string, id: number) {
        return instance.post('api/kyc/delete', {kycId: id} ,{headers: {Authorization: `Bearer ${token}`}})
    },
    updateKyc (token: string, data:any) {
        return instance.post('api/kyc/update', data ,{headers: {Authorization: `Bearer ${token}`}})
    },
};