import React, {useState} from 'react';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import './Header.css';
import logo from '../Assets/headerLogo.svg';
import MyBtn from "../ui/MyBtn/MyBtn";

const Header = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleScroll = (id) => {
        const anchor = document.getElementById(id);
        if (anchor) {
            anchor.scrollIntoView({behavior: "smooth"});
        }
    };

    return (
        <>
            <header className="container">
                <div className="block">
                    <Link className="header_link" to="/">
                        <img src={logo} alt="" className="header-logo"/>
                    </Link>
                    <Link className="header_link" to="/">Главная</Link>
                    <Link className="header_link" to="/aboutBank">О Банке</Link>
                    <Link to="/services" activeClassName="active">Тарифы и<br/>Услуги</Link>
                    <Link to="/corporate" className="header_link">Корпоративным<br/>клиентам</Link>
                    <Link to="/personal" className="header_link">Физическим<br/>клиентам</Link>
                    <Link to="/cards" className="header_link">Платежные<br/>Карты</Link>
                    <Link to="/crypto" className="header_link">Операции с<br/>криптовалютой</Link>

                    <div className="dropdown" onMouseEnter={() => setDropdownOpen(true)}
                         onMouseLeave={() => setDropdownOpen(false)}>
                        <span className="header_link" onClick={toggleDropdown}>Другие Услуги</span>
                        {dropdownOpen && (
                            <div className="dropdown-content">
                                <NavLink to="/other#deposit" onClick={() => handleScroll('deposit')}>Депозитный
                                    счет</NavLink>
                                <NavLink to="/other#cashing-checks" onClick={() => handleScroll('cashing-checks')}>Обналичивание
                                    банковских чеков</NavLink>
                                <NavLink to="/other#international-acquiring"
                                         onClick={() => handleScroll('international-acquiring')}>Международный
                                    эквайринг</NavLink>
                                <NavLink to="/other#operations-metals"
                                         onClick={() => handleScroll('operations-metals')}>Операции с драгоценными
                                    металлами</NavLink>
                                <NavLink to="/other#sberkassa-fx" onClick={() => handleScroll('sberkassa-fx')}>Меджународная
                                    СберКасса FX</NavLink>
                            </div>
                        )}
                    </div>

                    <Link to="/login">
                        <MyBtn title={"Войти"}/>
                    </Link>
                </div>
                <div>

                </div>
            </header>
        </>
    );
};

export default Header;
