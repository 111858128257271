import React, {useEffect, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import './bankTransfer.css';
import store, {useAppDispatch, useAppSelector} from "../../../store/store";
import {CreateAccountsThunk, getAccountsListThunk, SendTransferThunk} from "../../../store/reducers/actions";
import {setDisabled} from "../../../store/reducers/UserSlice";
import CustomModal from "../customModal/customModal";
import MyBtn from "../MyBtn/MyBtn";

const BankTransferForm = () => {
  const dispatch = useAppDispatch()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const {userToken} = useAppSelector(store => store.userReducer.user)
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
  const {isLoading} = useAppSelector(store => store.userReducer)
  const userAccounts = useAppSelector(store => store.userReducer.accountsList)
  const [minDate, setMinDate] = useState('');


  const handleOkClick = () => {
    setIsPopupOpen(false);
    window.location.href = '/kabinet';  };

  useEffect(() => {
    const today = new Date();
    const minDateValue = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    setMinDate(minDateValue);
  }, []);


  const currencies = [
    'USD',
    'EUR',
    'GBP',
    'CHF',
    'RUB',
    'TRY',
    'AED',
    'CNY',
    'AUD',
    'CZK',
    'PLN',
    'CAD',
    'USDT'
  ];

  const paymentRegions = [
    "SWIFT (Международный Перевод)",
    "SEPA",
    "ACH",
    "BACS",
    "INTERAC",
    "EFT",
    "PAN AFRICA",
  ]



  return (

      <div>
        <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
          <div className="title">
            Успешно!</div>
          <div className="modal-section">
            Ваша заявка на перевод была отправлена.
          </div>
          <div className="modal-section">
            <MyBtn medium title={"Ok"} onClick={() => {handleOkClick()}}/>
          </div>
        </CustomModal>

        <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
          <div className="title">
            Ошибка!</div>
          <div className="modal-section">
            Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
          </div>
          <div className="modal-section">
            <MyBtn medium title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
          </div>
        </CustomModal>

          <h2 style={{color: '#03045E'}}>Банковский Перевод</h2>
          <div className="bank-transfer-main-container">

      <Formik
        initialValues={{
          paymentMethod: '',
          paymentRegions: '',
          fromAccount: '',
          fullName: '',
          recipientAddress: '',
          recipientsAccountNumber: '',
          recipientsSwiftBic: '',
          recipientCountry: '',
          recipientsBankCountry: '',
          recipientsBankAddress: '',
          recipientsBank: '',
          email: '',
          transferDate: minDate,
          amount: '',
          currency: 'USD',
          description: '',
          templateName: '',
          type: 'international',
        }}
        onSubmit={(values, actions) => {
          dispatch(setDisabled(true))
          const recipientDetails = {
            fullName: values.fullName,
            recipientsSwiftBic: values.recipientsSwiftBic,
            recipientsBankCountry: values.recipientsBankCountry,
            recipientsBankAddress: values.recipientsBankAddress,
            recipientsBank: values.recipientsBank,
            recipientsIban: values.recipientsIban,
            recipientsAccountNumber: values.recipientsAccountNumber,
            recipientsAddress: values.recipientsAddress,
            country: values.recipientCountry?.value,
            phoneNumber: values.phoneNumber,
            recipientsEmail: values.email,
            recipientType: values.recipientType,
          };

          const data = {
            paymentRegions: values.paymentRegions,
            fromAccount: values.fromAccount,
            paymentDate: values.paymentDate,
            reference: values.reference,
            amount: Number(values.amount),
            type: values.type,
            purpose: values.purpose,
            templateName: values.templateName,
            recipient: values.bankAccount || values.phoneNumber || values.recipientsIban || values.recipientsAccountNumber || values.pixKeyValue,
            recipientDetails: recipientDetails
          };

          dispatch(SendTransferThunk(userToken, data))
              .then((res) => {
                if (res.data === 'Created') {
                  console.log('created')
                  setIsPopupOpen(true)
                } else {
                  console.log('error')
                  setIsErrorPopupOpen(true)
                }
              })
              .catch((e) => {
                console.log('error')
                setIsErrorPopupOpen(true)
              })
              .finally(() => {
                localStorage.removeItem("transfer-form")
                dispatch(setDisabled(false))
              })

        }}
      >
        <Form className="bank-transfer-form">
          <div className='transfer-container'>
            <div className="field-wrapper">
              <label htmlFor="fromAccount"><span className="required">* </span> Счет: </label>
              <Field as="select" name="fromAccount" id="fromAccount" required>
                <option style={{display: 'none'}}></option>
                {userAccounts.map(account => (
                    <option key={account.key} value={account.number}>{account.number}</option>
                ))}
              </Field>
            </div>
            <div className="field-wrapper">
              <label htmlFor="paymentRegions"><span className="required">* </span> регион оплаты: </label>
              <Field as="select" name="paymentRegions" id="paymentRegions" required>
                <option style={{display: 'none'}}></option>
                {paymentRegions.map(region => (
                    <option key={region.key} value={region}>{region}</option>
                ))}
              </Field>
            </div>
          </div>
          <h2 style={{color: '#03045E'}}>Детали Получателя</h2>
          <div className='transfer-container'>
            <div className="field-wrapper">
              <label htmlFor="fullName"><span className="required">* </span> Ф.И.О: </label>
              <Field type="text" name="fullName" id="fullName" placeholder="" required />
            </div>
            <div className="field-wrapper">
              <label htmlFor="recipientsAddress"><span className="required">* </span> Адресс Получателя </label>
              <Field type="text" name="recipientsAddress" id="recipientsAddress" placeholder="" required />
            </div>
          </div>
          <div className='transfer-container'>
            <div className="field-wrapper">
              <label htmlFor="recipientsAccountNumber"><span className="required">* </span> IBAN / Номер Счета: </label>
              <Field type="text" name="recipientsAccountNumber" id="recipientsAccountNumber" placeholder="" required />
            </div>
            <div className="field-wrapper">
              <label htmlFor="recipientsSwiftBic"><span className="required">* </span> SWIFT/BIC код: </label>
              <Field type="text" name="recipientsSwiftBic" id="recipientsSwiftBic" placeholder="" required />
            </div>
          </div>
          <div className='transfer-container'>
            <div className="field-wrapper">
              <label htmlFor="recipientsBankCountry"><span className="required">* </span> Страна Получателя: </label>
              <Field type="text" name="recipientsBankCountry" id="recipientsBankCountry" placeholder="" required />
            </div>
            <div className="field-wrapper">
              <label htmlFor="recipientsBank"><span className="required">* </span> Банк Получателя: </label>
              <Field type="text" name="recipientsBank" id="recipientsBank" placeholder="" required />
            </div>
          </div>
          <div className='transfer-container'>
            <div className="field-wrapper">
              <label htmlFor="recipientsBankAddress"><span className="required">* </span> Адресс Банка Получателя: </label>
              <Field type="text" name="recipientsBankAddress" id="recipientsBankAddress" placeholder="" required />
            </div>
            <div className="field-wrapper">
              <label htmlFor="email"><span className="required">* </span> Электронная Почта: </label>
              <Field type="email" name="email" id="email" placeholder="" required />
            </div>
          </div>
          <div className='transfer-details-container'>
            <h2 style={{color: '#03045E'}}>Детали перевода</h2>
            <div className='currency-selection'>
          {/*    <div className='field-wrapper'>
                <label htmlFor="paymentDate"><span className="required">* </span> Дата Перевода: </label>
                <Field type="date" name="paymentDate" id="paymentDate" placeholder="" min={minDate}  required />
              </div>*/}
              <div className="field-wrapper">
                <label htmlFor="amount"><span className="required">* </span> Сумма: </label>
                <Field type="text" name="amount" id="amount" placeholder="" required />
              </div>
              <div className="field-wrapper">
                <label htmlFor="transferCurrency"><span className="required">* </span> Валюта: </label>
                <Field as="select" name="transferCurrency" id="transferCurrency" required>
                  {currencies.map(currency => (
                      <option key={currency.key} value={currency}>{currency}</option>
                  ))}
                </Field>
              </div>
            </div>
            <div className="field-wrapper">
              <label htmlFor="reference"> Причина: </label>
              <Field as="textarea"  style={{ resize: 'none' }}  name="reference" id="reference" placeholder="Введите причину перевода" rows="4" />
            </div>
          </div>
  {/*        <div className='translation-details-container'>
            <h2>Сохранить детали перевода как шаблон</h2>
            <div className="field-wrapper">
              <label htmlFor="templateName">Наименование Шаблона: </label>
              <Field type="text" name="templateName" id="templateName" placeholder="" />
            </div>
          </div>*/}
          <div className='form-bottom-main-container'>
            <div className='form-bottom-text-container'>
              <p className='form-bottom-text'>
                <span className="required">* </span>  Обязательные поля
              </p>
            </div>
            <button type="submit">Подтвердить</button>
          </div>
        </Form>
      </Formik>
    </div>
      </div>


  );
};

export default BankTransferForm;
