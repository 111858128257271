import React, {useState} from 'react';
import SideMenu from './components/SideMenu/SideMenu';
import Accounts from './components/ui/currencyAccounts/currencyAccounts';
import Rate from './components/ui/currencyRate/currencyRate.jsx'
import TemplateSheet from "./components/ui/templateSheet/templateSheet";
import TransferForm from "./components/ui/BankTransferForm/transferForm";
import KycForm from "./components/ui/kycForm/kycForm";
import {useAppSelector} from "./store/store";

function SideMenuLayout() {

    const [activeMenuItem, setActiveMenuItem] = useState('');

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
    };

    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)

    const kycIsValid = () => {
        if (!kycStatus) {
            return false
        } else if (kycStatus === "New") {
            return false
        } else if (kycStatus === "Rejected") {
            return false
        } else {
            return true
        }
    }

    const renderComponent = () => {
        if (!kycIsValid()) {
            return <KycForm/>;
        }

        switch (activeMenuItem) {
            case 'accounts':
                return <Accounts/>;
            case 'rate':
                return <TemplateSheet/>;
            case 'transfer':
                return <TransferForm/>;
         /*   case 'template':
                return <TemplateSheet/>;*/
            case 'kyc':
                return <KycForm/>;
            default:
                return <Accounts/>;
        }
    };

    return (


        <div style={{display: 'flex'}}>
            <SideMenu onItemClick={handleMenuItemClick}/>
            <div className="сurrency-wrapper">
                <div className="main-container">
                    {/*  <Accounts />*/}
                    {renderComponent()}
                </div>
            </div>
        </div>
    );
}

export default SideMenuLayout;
