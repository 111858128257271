import React from 'react';
import './currencyRate.css'
import { Formik, Form, Field } from 'formik';
/*import { Table } from 'antd';*/
import currencyColumns from './currencyColumns'






const Rate = () => {
    const handleSubmit = (values) => {
        console.log('Form submitted with data:', values);
      };

      const handleButtonClick = () => {
        console.log('Обновить button clicked');
    };

      const data = [
        { key: 1, officialRate: 'Data 1', sberBankRate: 'Data 2', tinkoffRate: 'Data 3', bsbRate: 'Data 4', sberbankRate: 'Data 5' },
        { key: 2, officialRate: 'Data 6', sberBankRate: 'Data 7', tinkoffRate: 'Data 8', bsbRate: 'Data 9', sberbankRate: 'Data 10' },
    ];

    const tableColumns = currencyColumns.map(column => ({
        ...column,
        title: (
            <span className="column-title">
                {column.title}
            </span>
        ),
    }));

    const customStyles = {
        option: (provided, state) => ({
          fontWeight: state.data.isBold ? 'bold' : 'normal',
        }),
      };
    
  return (
      <>
    <div className='сurrency-wrapper'>
        <div className="main-container">
             <div className="currency-rate-layout">
      <div className='sale-container'>
        <Formik
            initialValues={{
            currency: '',
            currentValue: '',
            exchangeValue: '',
            rate:'',
          
            }}
            onSubmit={handleSubmit}
        >
                <Form className="sales-form">
        
                <div className="sales-purchase-container">
                    <div className='sale-field-wrapper'>
                        <div className='textBox-layout'>
                            <label htmlFor="sale">Sale</label>
                            <Field type="text" name="sale" id="sale" />
                        </div>
                        <div className='textBox-layout'>
                            <Field as="select" name="currency" id="currency">
                                <option value="">USD</option>
                                <option value="euro">Euro</option>
                            </Field>
                        </div>
                    </div>
                    <div className='sale-field-wrapper'>
                        <div className='textBox-layout'>
                            <label htmlFor="purchase">Purchase</label>
                            <Field type="text" name="purchase" id="purchase" />
                        </div>
                        <div className='textBox-layout'>
                            <Field as="select" name="purchase" id="purchase">
                                <option value="" style={{fontWeight:'bold'}}>BCE</option>
                            </Field>
                        </div>
                    </div>
                </div>
                <div className='all-sale-container '>
                    <div className='text-container'>
                        Сумма продажи: <span className="all-purchase-text">1.00 USD</span>; Покупка Все <span className="all-purchase-text">BCE</span>
                    </div>
                    <button type="submit" onClick={handleButtonClick} className='currency-rate-button'>Обновить</button>

                </div>
                    <div style={{ paddingTop: '50px' }}>

                    </div>  
                   {/*     <Table
                        dataSource={data} 
                        columns={tableColumns}
                        pagination={false}
                />*/}
        </Form>
      </Formik>

    </div>
    </div>
        </div>
    </div>
      </>
  );
};

export default Rate;
