import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import './SideMenu.css';

import btc from '../Assets/sideMenuIcons/btc.png';
import clipboard from '../Assets/sideMenuIcons/clipboard.png';
import settings from '../Assets/sideMenuIcons/settings.png';
import transfers from '../Assets/sideMenuIcons/transfers.png';
import usd from '../Assets/sideMenuIcons/usd.png';
import profileImage from '../Assets/sideMenuIcons/portrait.png';
import verified from '../Assets/sideMenuIcons/verified.png';
import creditCard from '../Assets/sideMenuIcons/creditCard.png';
import piechart from '../Assets/sideMenuIcons/piechart.png';
import logoutIcon from '../Assets/sideMenuIcons/logout.png';
import {getKycListThunk, GetTransactionsListThunk, LogoutThunk} from "../../store/reducers/actions";
import {useAppDispatch, useAppSelector} from "../../store/store";
import CustomModal from "../ui/customModal/customModal";
import MyBtn from "../ui/MyBtn/MyBtn";


const SideMenu = ({onItemClick}) => {

    const user = useAppSelector(store => store.userReducer.user.userData)

    let userName;
    if (user.firstName !== null && user.firstName !== undefined) {
        userName = `${user.firstName} ${user.lastName}`;
    } else {
        userName = user.companyName;
    }
    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)

    const isTokenValid: boolean = useAppSelector(state => state.userReducer.tokenIsValid)

    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)

    useEffect(() => {
        dispatch(getKycListThunk(userToken))
        dispatch(GetTransactionsListThunk(userToken))
    }, [])

    const kycIsValid = () => {
        if (!kycStatus) {
            return false
        } else if (kycStatus === "New") {
            return false
        } else if (kycStatus === "Rejected") {
            return false
        } else {
            return true
        }
    }

    const handleLogout = () => {

        dispatch(LogoutThunk())
            .then(() => {
                window.location.href = "/login";
            })
    }

    const handleClick = (menuItem) => {
        onItemClick(menuItem);
    };


    return (

        <div className='side-menu-wrapper'>
            <CustomModal isModalOpen={!isTokenValid} type={"confirm"}>
                <div className="title">
                    Ошибка!
                </div>
                <div className="modal-section">
                    Время вашей сессии истекло. Пожалуйста, войдите еще раз.
                </div>
                <div className="modal-section">
                    <MyBtn title={"Ok"} buttonType={"confirm"} onClick={() => handleLogout()}/>
                </div>
            </CustomModal>

            <div className="side-menu">
                <div className="profile-container">
                    <img src={profileImage} alt="Profile" className="profile-picture"/>
                    <div className="profile-details">
                        <div className="user-details">
                            <span className="user-name">{userName}</span>
                        </div>
                        {!kycIsValid()
                            ? <div className="verified-container">
                                <div className="verified-text-container">
                                    <span className="verified-text">Неидентифицированный клиент</span>
                                </div>
                                <div className="verified-icon-container">

                                </div>
                            </div>
                            : (
                                <div className="verified-container">
                                    <div className="verified-text-container">
                                        <span className="verified-text">Идентифицированный клиент</span>
                                    </div>
                                    <div className="verified-icon-container">
                                        <img src={verified} alt="Verified" className="verified-icon"/>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                {
                    !kycIsValid()
                        ?   <div className="menu-icons">

                            <MenuItem icon={creditCard} name="Верификация" onClick={() => handleClick('kyc')}/>

                        </div>
                        :
                        <div className="menu-icons">

                            <MenuItem icon={creditCard} name="Счета" onClick={() => handleClick('accounts')}/>
                            <MenuItem icon={transfers} name="Переводы" onClick={() => handleClick('transfer')}/>
                         {/*   <MenuItem icon={clipboard} name="Шаблоны" onClick={() => handleClick('template')}/>
                       */}     <MenuItem icon={usd} name="Конвертация" onClick={() => handleClick('rate')}/>
                            <MenuItem icon={btc} name="Крипто-Обмен" onClick={() => handleClick('crypto')}/>
                            <MenuItem icon={piechart} name="СберКасса FX" onClick={() => handleClick('chart')}/>

                        </div>
                }

                <div className='menuBottom'>
                    <MenuItem icon={settings} name="Настройки" onClick={() => handleClick('settings')}/>
                    <div className="menu-icon" onClick={handleLogout}>
                        <img src={logoutIcon} alt="Выход" className='iconStyle'/>
                        <span className="icon-name">Выход</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MenuItem = ({icon, name, link, onClick}) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className="menu-icon" onClick={handleClick}>
            {link ? (
                <Link to={link} className="menu-link">
                    <img src={icon} alt={name} className='iconStyle'/>
                    <span className="icon-name">{name}</span>
                </Link>
            ) : (
                <div className="menu-link">
                    <img src={icon} alt={name} className='iconStyle'/>
                    <span className="icon-name">{name}</span>
                </div>
            )}
        </div>
    );
};


export default SideMenu;
