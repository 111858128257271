import React from 'react';
import {useNavigate} from 'react-router-dom';
import Planet from '../Assets/planet.svg';
import MyBtn from '../ui/MyBtn/MyBtn';
import './Home.css';
import MyInput from "../ui/MyInput/MyInput";

const Home = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="home-wrapper">
                <div className="home-container">
                    <div className="text-section">
                        <div className="title-block">
                            <div className="text-content">
                                <div className="home-title">БАНК ДЛЯ <br/> МЕНЯЮЩЕГОСЯ МИРА</div>
                                <div className="home-description">
                                    Банк способствует экономическому и социальному развитию резидентов <br/>
                                    славянских стран на международной арене. Мы содействуем становлению <br/>
                                    и развитию бизнеса своих клиентов путем предоставления широкого спектра <br/>
                                    банковских услуг высокого качества и использования передовых банковских <br/>
                                    технологий.
                                </div>
                                <div className="btn-block">
                                    <MyBtn
                                        title="Подробнее" large
                                        onClick={() => navigate("/aboutBank")}
                                    />
                                </div>
                            </div>
                            <div className="image-container">
                                <img src={Planet} alt="Planet" style={{maxHeight: 496}}/>
                            </div>
                        </div>
                    </div>

                    <div className="quote-container">
                        <div className="quote-text">“Международная СберКасса - это способ уверенного маневрирования
                            Вашего Бизнеса в сторону успеха.”
                        </div>
                    </div>

                    <div className="text-section">
                        <div className="text-block">
                            <div className="finance-title">Банк сегодня</div>
                            <div className="finance-content">
                                Международная СберКасса осуществляет операции с Мая 2022 года. Мы обслуживаем
                                индивидуальных и корпоративных клиентов различных форм собственности и направлений
                                деятельности.
                            </div>
                            <MyBtn
                                title="О Банке" large
                                onClick={() => navigate("/aboutBank")}
                            />
                        </div>


                    </div>

                    <div className="benefit-row" style={{paddingTop: '64px'}}>
                        <div className="benefit-no">01</div>
                        <div className="benefit-text">
                            Развитие бизнеса Банка строится на предоставлении клиентам широкого спектра
                            высокотехнологических банковских продуктов и услуг на уровне международных стандартов.
                        </div>
                    </div>

                    <div className="benefit-row">
                        <div className="benefit-no">02</div>
                        <div className="benefit-text">
                            Банк «Международная СберКасса» готов предоставить гарантию успешных платежей для
                            русскоязычных Клиентов, вне зависимости от политических границ.
                        </div>
                    </div>

                    <div className="benefit-row">
                        <div className="benefit-no">03</div>
                        <div className="benefit-text">
                            Широкий список Банков - Корреспондентов позволяет нам осуществлять переводы любой сложности
                            в любую точку мира.
                        </div>
                    </div>

                    <div className="benefit-row">
                        <div className="benefit-no">04</div>
                        <div className="benefit-text">
                            Вместе с Международной СберКассой вы сможете с уверенностью совершать сделки, инвестировать
                            в недвижимость, а также преумножать свой капитал.
                        </div>
                    </div>


                    <div className="text-section">
                        <div className="cards-section">
                            <div className="cards-text">
                                <div className="home-title">
                                    Наши эксклюзивные преимущества
                                </div>
                                <div className="cards-info">
                                    "Расширьте ваш финансовый опыт с нашим эксклюзивным пакетом преимуществ,
                                    разработанным для обеспечения удобства и безопасности. Исследуйте мир возможностей и
                                    спокойствия, выбирая нас."
                                </div>
                            </div>
                            <div className="cards-container">
                                <div className="card">
                                    <div className="card-title">Международная
                                        ориентация
                                    </div>
                                    <div className="card-content">“Поддерживаем экономическое и социальное развитие
                                        резидентов славянских стран на международной арене, обеспечивая доступ к
                                        передовым банковским услугам.”
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-title">Широкий спектр
                                        услуг
                                    </div>
                                    <div className="card-content">"Предлагаем клиентам разнообразные банковские услуги
                                        высокого качества, включая услуги по финансированию, инвестированию, и
                                        обслуживанию счетов."
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-title">Инновационные технологии
                                    </div>
                                    <div className="card-content">"Используем передовые банковские технологии, чтобы
                                        обеспечить удобство и безопасность для наших клиентов."
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-title">Безопасность и Надежность
                                    </div>
                                    <div className="card-content">"Осуществляем банковские операции с высоким уровнем
                                        безопасности и надежности, защищая интересы наших клиентов."
                                    </div>
                                </div>


                            </div>
                            <div className="cards-btn">
                                <MyBtn
                                    title="Открыть Счет" large
                                    onClick={() => navigate("/register")}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="quote-container" style={{display: 'inline', maxWidth: '1200px'}}>
                        <div className="quote-text">"Мы с уважением относимся к каждому резиденту <br/> нашей Земли."
                        </div>
                    </div>

                    <div className="contact-section">
                        <div className="contact-title">
                            <div className="home-title" style={{textTransform: 'none'}}>
                                Получите <b>консультацию</b> специалиста
                            </div>
                        </div>
                        <div className="contact-form">
                            <form>
                                <div className="input-block">
                                    <div className="label">ФИО контактного лица</div>
                                    <MyInput
                                        id="name"
                                        name="name"
                                        placeholder="Виктория Давлатова"
                                    />
                                </div>
                                <div className="input-block">
                                    <div className="label">E-MAIL</div>
                                    <MyInput
                                        id="email"
                                        name="email"
                                        placeholder="bub@mail.ru"
                                    />
                                </div>
                                <div className="input-block">
                                    <div className="label">Телефон</div>
                                    <MyInput
                                        id="phone"
                                        name="phone"
                                        placeholder="Контактный номер телефона"
                                    />
                                </div>

                                <MyBtn
                                    title="Отправить" large style={{width: '420px'}}
                                    onClick={() => navigate("/register")}
                                />

                            </form>

                        </div>
                    </div>


                </div>
            </div>

        </>

    );
};

export default Home;
