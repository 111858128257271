import React from 'react'
import {useNavigate} from 'react-router-dom';
import MyBtn from '../ui/MyBtn/MyBtn'
import maincard from '../Assets/cardIcons/mainCard.png'
import creditCard from '../Assets/cardIcons/creditCard.png'
import physicalCard from '../Assets/cardIcons/physicalCard.png'
import './Cards.css'


function Cards() {
    const navigate = useNavigate();


    return (
        <>
            <div className='home-wrapper'>
                <div className='cards-main-container'>
                    <div className='card-main-header-container'>
                        <div className='card-text-container'>
                            <h2 className='card-header'>
                                Дебетовые Карты
                            </h2>
                            <div className='card-text-container'>
                                <p className="card-text">
                                    Дебетовые карты (пластиковые, металлические, виртуальные, неименные) - <br/>
                                    возможность осуществлять безналичные расчеты, снятие наличных, перевод с <br/>
                                    карты на карту, а также другие операции по запросу через Ваш Онлайн- <br/>
                                    Банкинг.
                                </p>
                            </div>
                            <MyBtn title="Заказать Карту" large
                                   onClick={() => navigate("/register")}
                            />
                        </div>
                        <div className='card-main-image-conatainer'>
                            <img src={maincard}
                                 alt=''
                                 style={{height: "400px"}}
                            />

                        </div>
                    </div>
                    <div className='creditCard-container'>

                        <img src={creditCard} style={{width: '450px'}}
                             alt=''
                             className='credit-card-style'
                        />
                    </div>
                    <div className='virtual-card-container '>
                        <h1 className='virtual-card-header '>
                            Виртуальная карта
                        </h1>
                        <div className="salary-card-container">
                            <div className="card-names">
                                <button className="card-name-button">Uzcard</button>
                                <button className="card-name-button">Humo</button>
                                <button className="card-name-button">UnionPay</button>
                                <button className="card-name-button">Sumovaya</button>
                                <button className="card-name-button">Salary</button>
                            </div>
                            <div className="card-details">
                                <div className="detail">
                                    <h2>Платежная система</h2>
                                    <p className="free-text">MasterCard</p>
                                </div>
                                <div className="detail">
                                    <h2>Обслуживание</h2>
                                    <p className="free-text">Бесплатно</p>
                                </div>
                            </div>
                            {/* <button className="more-details-button">More details</button>*/}
                            {/* <p className="info-paragraph">
                    Humo salary card or UzCard/UnionPay co-branding card allows you to
                    conveniently manage your wages. Your employer, based on the salary
                    project agreement, will transfer your salary monthly to your plastic card account.
                </p> */}
                        </div>
                    </div>

                    <div className='creditCard-container'>

                        <img src={physicalCard} style={{width: '450px'}}
                             alt=''
                             className='credit-card-style'
                        />
                    </div>
                    <div className='virtual-card-container '>
                        <h1 className='virtual-card-header '>
                            Физическая карта
                        </h1>
                        <div className="salary-card-container">
                            <div className="card-names">
                                <button className="card-name-button">Uzcard</button>
                                <button className="card-name-button">Humo</button>
                                <button className="card-name-button">UnionPay</button>
                                <button className="card-name-button">Sumovaya</button>
                                <button className="card-name-button">Salary</button>
                            </div>
                            <div className="card-details">
                                <div className="detail">
                                    <h2>Платежная система</h2>
                                    <p className="free-text">MasterCard</p>
                                </div>
                                <div className="detail">
                                    <h2>Обслуживание</h2>
                                    <p className="free-text">Бесплатно</p>
                                </div>
                            </div>
                            {/* <button className="more-details-button">More details</button>*/}
                            {/* <p className="info-paragraph">
                    Humo salary card or UzCard/UnionPay co-branding card allows you to
                    conveniently manage your wages. Your employer, based on the salary
                    project agreement, will transfer your salary monthly to your plastic card account.
                </p> */}
                        </div>
                    </div>

                {/*    <div className='physical-card-container'>
                        <div className='physical-container '>

                            <img src={physicalCard}
                                 alt=''
                            />
                        </div>
                        <div className='virtual-card-container '>
                            <h1 className='virtual-card-header '>
                                Физическая карта
                            </h1>
                            <div className="salary-card-container">
                                <div className="card-names">
                                    <button className="card-name-button">Uzcard</button>
                                    <button className="card-name-button">Humo</button>
                                    <button className="card-name-button">UnionPay</button>
                                    <button className="card-name-button">Sumovaya</button>
                                    <button className="card-name-button">Salary</button>
                                </div>
                                <div className="card-details">
                                    <div className="detail">
                                        <h2>Платежная система</h2>
                                        <p className="free-text">MasterCard</p>
                                    </div>
                                    <div className="detail">
                                        <h2>Обслуживание</h2>
                                        <p className="free-text">Бесплатно</p>
                                    </div>
                                </div>
                                <button className="more-details-button">More details</button>

                            </div>
                        </div>

                    </div>*/}


                </div>
            </div>


        </>
    )
}

export default Cards