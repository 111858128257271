import React, {useEffect, useState} from "react";
import "./currencyAccounts.css";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {CreateAccountsThunk, getAccountsListThunk, getLoginList} from "../../../store/reducers/actions";
import MyBtn from "../MyBtn/MyBtn";
import CustomModal from "../customModal/customModal";
import {Field, Form, Formik} from "formik";
import Transactions from "../Transactions/transactions";

const Accounts = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const {isLoading} = useAppSelector(store => store.userReducer)
    const accounts = useAppSelector(store => store.userReducer.accountsList)

    useEffect(() => {
        dispatch(getAccountsListThunk(userToken))
    }, [])

    const currencies = [
        'USD',
        'EUR',
        'GBP',
        'CHF',
        'RUB',
        'TRY',
        'AED',
        'CNY',
        'AUD',
        'CZK',
        'PLN',
        'CAD',
        'USDT'
    ];


    return (
        <>

            <CustomModal isModalOpen={isPopupOpen} error setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Добавить Счет</div>
                <div className="modal-section">
                    Выберите валюту для нового счета.
                </div>
                <Formik
                    initialValues={{
                        currency: '',
                    }}
                    onSubmit={(values, actions) => {
                        let transformedValues = {
                            currencies: [values.currency.trim()]
                        };

                        dispatch(CreateAccountsThunk(userToken, transformedValues))
                            .then((res) => {
                                if (res.status === 200) {
                                    console.log('Form submitted with data:');
                                    dispatch(getAccountsListThunk(userToken))
                                    setIsPopupOpen(false);
                                }
                            }).catch((e) => {
                            console.log('Form not submitted ');
                        })
                            .finally(() => {
                                actions.setSubmitting(false);
                            });

                    }}
                >
                    <Form>
                        <div className="modal-section" >
                <Field as="select" name="currency" id="currency" required style={{width: '60%'}}>
                    <option style={{display: 'none'}}></option>
                    {currencies.map(currency => (
                        <option key={currency.key} value={currency}>{currency}</option>
                    ))}
                </Field></div>
                        <div className="modal-section" >
                        <MyBtn medium title={"Подтвердить"} type="submit" style={{marginRight: 8}}/>
                            <MyBtn medium title={"Отмена"} onClick={() => {setIsPopupOpen(false)}}/>
                        </div>
                    </Form>
                </Formik>

            </CustomModal>


                <div className="currency-accounts-header">
                        <h2>Валютные Счета</h2>
                        <div className="button-container">
                            <MyBtn type="submit" title={"+ Добавить Счет"} onClick={() => {setIsPopupOpen(true)}} className="cuurency-account-button"/>
                        </div>
                    </div>

            <div className="account-currency-container">
                <div className="account-table">
                    <div className="account-table-row header">
                        <div className="table-cell">Номер Счета</div>
                        <div className="table-cell">Сумма</div>
                    </div>
                    {accounts.map(account => (
                        <div className="account-table-row" key={account.number}>
                            <div className="table-cell cellText">{account.number}</div>
                            <div className="table-cell cellText">{account.balance} {account.currency}</div>
                        </div>
                    ))}
                </div>
            </div>

            <Transactions/>


        </>
    );
};

export default Accounts;
