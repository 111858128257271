import React, {useEffect, useState} from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import {Outlet} from 'react-router-dom';
import './Layout.css';

function Layout() {
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [isComponentsRendered, setIsComponentsRendered] = useState(false);

    useEffect(() => {
        const updateHeights = () => {
            const headerElement = document.querySelector('.header');
            const footerElement = document.querySelector('.footer');
            if (headerElement && footerElement) {
                setHeaderHeight(headerElement.offsetHeight);
                setFooterHeight(footerElement.offsetHeight);
                setIsComponentsRendered(true);
            }
        };

        window.addEventListener('resize', updateHeights);
        updateHeights();
        return () => {
            window.removeEventListener('resize', updateHeights);
        };
    }, []);

    const homeContainerStyle = isComponentsRendered ? {
        height: `calc(100vh - ${headerHeight}px - ${footerHeight}px - 40px)`,
    } : {};

    return (
        <>
            { <div className="layout-container">
                <div className="desktop_header">
            <Header />
                </div>
            <div className="content-container">
                <Outlet />              
            </div>
            <div className='footer-container'>
                <Footer />
            </div>
        </div> }
           
        </>
    );
}

export default Layout;
