import React, { useState, useEffect, useRef } from 'react';
import { useFormikContext, useField } from 'formik';
import selectArrow from '../../Assets/icons/select_arrow.svg';
import s from './MyDropdown.module.css';

const MyDropdown = (props) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props.name);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleSelectItem = (item) => {
        setFieldValue(props.name, item); // Use Formik's setFieldValue to set the field value
        setIsDropdownVisible(false);
    };

    return (
        <div className={s.phoneInputWrapper} ref={dropdownRef}>
            <div className={s.mySelect}>
                <div className={s.selectedBlock} onClick={toggleDropdown}>
                    <img
                        className={isDropdownVisible ? s.arrowReverse : s.arrow}
                        width={9}
                        height={5}
                        src={selectArrow}
                        alt=""
                    />
                </div>
                {isDropdownVisible && (
                    <div className={s.optionsWrapper}>
                        {props.items.map((item, index) => (
                            <div
                                className={s.option}
                                key={index}
                                onClick={() => handleSelectItem(item)}
                            >
                                <div className={s.countryName}>{item}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <input
                className={`${s.my_input} ${props.isError && props.touched && s.error}`}
                id={props.id}
                name={props.name}
                type="text"
                value={field.value}
                placeholder={props.placeholder}
                readOnly
            />
        </div>
    );
};

export default MyDropdown;
