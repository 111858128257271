import React from 'react'
import MyBtn from "../ui/MyBtn/MyBtn";
import Notes from "../Assets/clients/notes.svg";
import Person from "../Assets/clients/person.svg";
import Group from "../Assets/clients/group.svg";
import List from "../Assets/clients/list.svg";
import Thumb from "../Assets/clients/thumb.svg";
import ArrowUp from "../Assets/clients/arrowUp.svg";
import {useNavigate} from "react-router-dom";
import './CorporateClients.css';

function CorporateClients() {
    const navigate = useNavigate();
    return (
        <>
            <div className="home-wrapper">
                <div className="home-container">
                    <div className="text-section">
                        <div className="title-block">
                            <div className="text-content">
                                <div className="home-title">Корпоративным Клиентам</div>
                                <div className="home-description">
                                    Международная СберКасса предоставляет возможность ĸорпоративным ĸлиентам осуществить
                                    поставленные цели и задачи в ĸратчайшие сроĸи. Изучив Вашу деятельность и задачи,
                                    мы сможем предложить наиболее выгодные ставĸи и услуги, а таĸже увеличить доходность
                                    без необходимости налогообложения.
                                </div>
                                <div className="btn-block">
                                    <MyBtn
                                        title="Открыть Счет" large
                                        onClick={() => navigate("/register")}
                                    />
                                </div>
                            </div>
                            <div className="image-container">
                                <img src={Notes} alt="Notes" style={{height: '326px'}}/>
                            </div>
                        </div>
                    </div>



                    <div className="text-container" style={{justifyContent: 'start'}}>
                        <div>
                            <div className="text-content">
                                <div className="home-title" style={{paddingBottom: '35px'}}>Открытие Счета</div>
                                <div className="home-description">
                                    Процесс отĸрытия Корпоративного Счета варьируется от 3-ех до 20-ти банĸовсĸих дней,
                                    в зависимости от запрашиваемого типа Счета.
                                </div>
                            </div>
                        </div>

                    </div>
                    <div >
                        <div className="client-card">
                            <div className="card-info">
                            <span className="icon">
                                <img src={Person} alt="Person"/>
                            </span>
                                <span >
                                <div className="client-title">
                                    Корпоративный Счет с Предоставлением Личного IBAN Номера
                                </div>
                                <div className="card-description">
                                    Время ожидания: 5-20 банĸовсĸих дней
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="client-card">
                            <div className="card-info">
                            <span className="icon">
                                <img src={Group} alt="Person"/>
                            </span>
                                <span>
                                <div className="client-title">
                                   Общий Корпоративный Счет
                                </div>
                                <div className="card-description" style={{paddingTop: '59px'}}>
                                    Время ожидания: 3-20 банĸовсĸих дней
                                </div>
                            </span>
                            </div>
                        </div>

                    </div>

                    <div className="text-container">
                        <div className="icon-row">
                            <img src={List} alt="icon"/>
                            <img src={Thumb} alt="icon"/>
                            <img src={ArrowUp} alt="icon"/>
                        </div>
                    </div>

                    <div className="text-container">
                        <div>
                            <div style={{width: '80%'}}>
                                <div className="home-title">Список Необходимых Документов Для
                                    Открытия Корпоративного Счета:
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="list-wrapper">
                        <ul className="underscored-list">
                            <li>Свидетельство о регистрации</li>
                            <li>Копия учредительных документов</li>
                            <li>Документ<span className="muted">(ы)</span>, подтверждающий назначение директора<span className="muted">(ов)</span> и секретаря компании <span className="muted">(при наличии)</span></li>
                            <li>Копия корпоративного реестра <span className="muted">(который включает в себя реестр акционеров, директоров и секретаря)</span></li>
                            <li>Документ, подтверждающий местонахождение юридического адреса компании</li>
                            <li>Свидетельство о хорошей репутации <span className="muted">(в случае, если компания была зарегистрирована более 12 месяцев назад)</span></li>
                            <li>Действующая лицензия <span className="muted">(если применимо)</span></li>
                            <li>Нотариально заверенная копия действующего паспорта бенефициара</li>
                            <li>Оригинал или заверенная копия счета за коммунальные услуги / выписка из банка</li>
                            <li>Доверенность <span className="muted">(при наличии)</span></li>
                            <li>Полностью заполненная и подписанная анкета для открытия корпоративного банковского счета
                                в Международной СберКассе</li>
                            <li>Личное резюме</li>
                        </ul>
                    </div>




                </div>
            </div>
        </>
    )
}

export default CorporateClients
