import React, {useState} from 'react';
import './ForgotPassword.css';
import MyInput from "../../ui/MyInput/MyInput";
import MyBtn from "../../ui/MyBtn/MyBtn";
import {resetPassword} from "../../../store/reducers/actions";
import {Formik} from "formik";
import {useAppDispatch} from "../../../store/store";
import {Link} from "react-router-dom";
import BackIcon from "../../Assets/icons/arrow.svg";
import Logo from "../../Assets/logo_with_text.svg";
import CustomModal from "../../ui/customModal/customModal";

function ForgotPassword() {

    const dispatch = useAppDispatch()
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>

            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!</div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                <div className="title">
                    Успешно!</div>
                <div className="modal-section">
                    Новый пароль для вашей учетной записи был
                    выслан на имейл.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {setIsModalOpen(false)}}/>
                </div>
            </CustomModal>

            <div className="wrapper">

                <div className="login_wrapper_content">
                    <Link to='/login' className="back_arrow_icon">
                        <img src={BackIcon} alt="Back" />
                    </Link>
                    <div className="sign_in_wrapper">
                        <div className="sign_in_content">
                            <img src={Logo} alt="Logo"/>
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                onSubmit={(values, actions) => {
                                    dispatch(resetPassword(values))
                                        .then(() => {
                                                setIsModalOpen(true);
                                        })
                                        .catch(error => {
                                            console.error('Reset error:', error);
                                            setIsErrorPopupOpen(true)
                                        })
                                        .finally(() => {
                                            actions.setSubmitting(false);
                                        });
                                }}
                            >
                                {formikProps => (
                                    <form onSubmit={formikProps.handleSubmit}>
                                        <div className="sign_in_content">
                                            <div className="title">
                                                Утратили доступ?
                                                <div className="info">
                                                    Введите свою электронную почту привязанную к вашему личному
                                                    аккаунту СберКассы.
                                                </div>
                                            </div>

                                            <div className="my_input">
                                                <MyInput
                                                    id="email"
                                                    name="email"
                                                    placeholder="Введите почту"
                                                    value={formikProps.values.email}
                                                    onChange={formikProps.handleChange}
                                                />
                                            </div>

                                            <div className="btn_block">
                                                <MyBtn title={"Продолжить"} type={"submit"} style={{height: '50px', width: '200px'}}/>
                                            </div>

                                            <div className="dont_have_account">
                                                Нет аккаунта СберКассы?
                                                <div className="register_link">
                                                    <a href="/register">Зарегистрироваться</a>
                                                </div>

                                            </div>

                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default ForgotPassword